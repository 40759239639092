body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #292D32; /* Your desired background color */
  color: white; /* Ensuring text is readable */
  font-family: Arial, sans-serif; /* Ensure consistent font styling */
}

.home {
  background-color: #292D32;
  color: #F5F5F5;
  /* display: flex; */
}

.header {
  /* background-color: #3D5A80; */
  background: linear-gradient(to bottom, #3D5A80, #292D32);
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center;     /* Center the image vertically */
  padding: 20px; /* Example background color */
  color: white;
}

.header .header-img {
  width: 90px;
  height: auto;
  /* margin-right: 10px; Optional: add some space between the logo and the name */
}

.header .name {
  font-size: 40px;
  /* background-color: #F5F5F5; */
  padding: 5px 10px; /* Optional: add some padding for better appearance */
  /* color: #292D32; */
  margin: 0 auto; /* Centering the name */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.hero {
  position: relative;
  /* background-color: #3D5A80; */
  text-align: center;
  /* padding-left: 40px;
  padding-right: 40px; */
  /* margin: 5%; */
  /* border-radius: ; */
  /* color: black; */
}



.hero .hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero .hero-img {
  width: 90vw;
  height: auto;
  border-radius: 1cm;
}

.posts {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 40px;
  /* min-width: 80%; */
  /* background-color: #f4f4f4; */
  /* min-height: 100vh; Ensure it covers the full height of the viewport */
}

.post-container {
  background-color: #F5F5F5;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 87.5vw;
  width: 100%;
  color: #292D32;
}

.post-sections {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.about-us, .how-it-works {
  flex: 1;
  margin-bottom: 20px;
  text-align: center;
}

.about-us h2, .how-it-works h2 {
  margin-top: 0;
}

p {
  margin: 10px 0;
}

.footer {
  /* background-color: #333; */
  /* color: white; */
  padding: 20px;
  text-align: center;
}

.footer .contact-info, .footer .footer-links, .footer .social-media {
  margin-bottom: 10px;
}

.footer .footer-links a, .footer .social-media a {
  /* color: white; */
  margin: 0 10px;
  text-decoration: none;
}
